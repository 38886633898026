import { FC } from 'react';
import styles from './custon-image.module.less';

interface ICustomImageProps {
    src: string;
}

export const CustomImage: FC<ICustomImageProps> = ({ src }) => {
    return (
        <div
            className={styles.card__image}
            style={{
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 80%',
            }}
        />
    );
};
