import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import { Routes } from './routes/routes';
import { UsersState } from './context/user-context/user-state';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ConfigProvider locale={ruRU}>
        <UsersState>
            <Routes />
        </UsersState>
    </ConfigProvider>
);
