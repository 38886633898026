import { useState, useEffect, FC, useMemo } from 'react';
import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';
import { Preloader } from '../../components/preloader';
import styles from './cars-page.module.less';
import { CarAPI } from '../../api/cars-api';
import { ICarsData } from '../../types/car-types';
import { CarsList } from '../../components/car-list/cars-list';
import { MainPagePagination } from '../../components/pagination';
import { AxiosError } from 'axios';
import { catalogTypes, DEFAULT_CATALOG_TYPE } from '../../variables';
import { UserAPI } from '../../api/user-api';

export const CarsPage: FC = () => {
    const [carsData, setCarsData] = useState<ICarsData>();
    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );
    const page = searchParams.get('page') ?? '1';
    const catalogType =
        searchParams.get('catalog_type') ?? DEFAULT_CATALOG_TYPE;
    const initialPage = parseInt(page);

    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const [activeCatalog, setActiveCatalog] = useState<string>(catalogType);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        navigate(`/cars?page=${page}&catalog_type=${activeCatalog}`);
    };

    const handleCatalogChange = (type: string) => {
        setActiveCatalog(type);
        setCurrentPage(1);
        navigate(`/cars?page=1&catalog_type=${type}`);
    };

    useEffect(() => {
        const page: string = searchParams.get('page') ?? '1';
        const catalog: string =
            searchParams.get('catalog_type') ?? DEFAULT_CATALOG_TYPE;
        setCurrentPage(parseInt(page));
        setActiveCatalog(catalog);
    }, [searchParams]);

    useEffect(() => {
        const fetchData = async () => {
            const user = await UserAPI.checkCurrentUser();
            if (localStorage.getItem('token') && !user) {
                return navigate('/logout/', { replace: true });
            }
            const data = await CarAPI.getCars({
                params: {
                    page: currentPage,
                    catalog_type: activeCatalog,
                },
            });
            if (data instanceof AxiosError) {
                return;
            } else {
                setCarsData(data);
            }
        };
        fetchData();
    }, [currentPage, activeCatalog, navigate]);

    if (!carsData) {
        return <Preloader />;
    }

    return (
        <div className={styles.page__wrapper}>
            <div className={styles.tabs__wrapper}>
                {catalogTypes.map((catalog) => (
                    <button
                        key={catalog.id}
                        onClick={() => handleCatalogChange(catalog.id)}
                        className={`${styles.tab__button} ${activeCatalog === catalog.id ? styles.active : ''}`}
                    >
                        {catalog.title}
                    </button>
                ))}
            </div>
            <div className={styles.title__wrapper}>
                {activeCatalog === DEFAULT_CATALOG_TYPE ? (
                    <h1>Аренда и продажа автомобей</h1>
                ) : (
                    <h1>Aвтомобили для инвестирования</h1>
                )}
            </div>
            <div className={styles.content__wrapper}>
                <CarsList cars={carsData.results} catalogType={activeCatalog} />
                {carsData.count > 12 && (
                    <div className={styles.pagination__wrapper}>
                        <MainPagePagination
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            total={carsData.count}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
