import { FC, useEffect, useState } from 'react';
import { ServiceAPI } from '../../api/services-api';
import { IAllTechnicalServices } from '../../types/service-types';
import styles from './service-example-page.module.less';
import { Image } from 'antd';

export const ServiceExamplePage: FC = () => {
    const [services, setServices] = useState<IAllTechnicalServices[]>([]);
    useEffect(() => {
        const fetchServicesData = async () => {
            const services = await ServiceAPI.getAllTechnicalServices();

            if (services) {
                setServices(services);
            }
        };
        fetchServicesData();
    }, []);
    return (
        <div className={styles.page__wrapper}>
            <h1 className={styles.page__header}>Как выполнять сервисы</h1>
            <div className={styles.services__wrapper}>
                {services
                    .filter((service) => service.service_photos?.length)
                    .map((service) => (
                        <div key={service.id}>
                            <h3>{service.name}</h3>
                            <div>
                                <p>
                                    <b>Описание: </b>
                                    {service.description}
                                </p>
                            </div>
                            {service.comment && (
                                <div>
                                    <p>
                                        <b>Пример комментария: </b>
                                        {service.comment} дн.
                                    </p>
                                </div>
                            )}
                            {service.periodicity && (
                                <div>
                                    <p>
                                        <b>Периодичность: </b>
                                        {service.periodicity} дн.
                                    </p>
                                </div>
                            )}
                            <div className={styles.photos}>
                                {service.service_photos?.map((photo) => (
                                    <div
                                        key={photo.id}
                                        className={styles.image__wrapper}
                                    >
                                        <Image
                                            src={photo.photo}
                                            className={styles.image}
                                        />
                                    </div>
                                ))}
                            </div>
                            <hr className={styles.divider} />
                        </div>
                    ))}
            </div>
        </div>
    );
};
