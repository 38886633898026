import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import styles from './float.module.less';

export const Float: React.FC = () => {
    return (
        <div className={styles.float__button}>
            <a href="tel:+79539708877" className={styles.float__link}>
                <PhoneOutlined className={styles.icon} />
            </a>
        </div>
    );
};
