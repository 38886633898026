import React, { useContext } from 'react';
import type { FormProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { UserAPI } from '../../api/user-api';
import { ILoginFieldType } from '../../types/user-register-types';
import { UsersContext } from '../../context/user-context/user-context';
import { OrangeButton } from '../../components/buttons/orange-button';
import styles from './login-page.module.less';

const onFinishFailed: FormProps<ILoginFieldType>['onFinishFailed'] = (
    errorInfo
) => {
    // TODO Нужно ли мне onFinishFailed или нет
    return errorInfo;
};

export const LoginPage: React.FC = () => {
    const setToken = useContext(UsersContext)?.setToken;

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish: FormProps<ILoginFieldType>['onFinish'] = async (values) => {
        const data = await UserAPI.login(values.phone, values.password);
        if (data && setToken) {
            setToken(data.access);
            navigate('/user/', { replace: true });
        }
    };

    return (
        <div className={styles.page__wrapper}>
            <h1>Авторизация</h1>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600, textAlign: 'center' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <h3>Телефон</h3>
                <Form.Item<ILoginFieldType>
                    name="phone"
                    className={styles.form__item}
                    rules={[{ required: true, message: 'Введите номер!' }]}
                >
                    <Input className={styles.input} />
                </Form.Item>
                <h3>Пароль</h3>
                <Form.Item<ILoginFieldType>
                    className={styles.form__item}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Введите пароль!',
                        },
                    ]}
                >
                    <Input.Password className={styles.password__input} />
                </Form.Item>
                <Form.Item
                    className={styles.button__item}
                    wrapperCol={{ offset: 8, span: 16 }}
                >
                    <OrangeButton
                        children="Войти"
                        handleClick={() => onFinish}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
