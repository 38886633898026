import { FC } from 'react';
import { ICar } from '../types/car-types';
import { Typography } from 'antd';
import { INVEST_CATALOG_TYPE } from '../variables';

interface ICarDescriptionProsp {
    car: ICar;
    catalogType: string;
}
const { Text } = Typography;

export const CarDescription: FC<ICarDescriptionProsp> = ({
    car,
    catalogType,
}) => {
    return (
        <>
            <div>
                <Text strong>Марка авто: </Text>
                <Text>{car.name}</Text>
            </div>
            <div>
                <Text strong>Описание: </Text>
                <Text>{car.description}</Text>
            </div>
            <div>
                <Text strong>Цена: </Text>
                <Text>{car.price} ₽</Text>
            </div>
            {catalogType === INVEST_CATALOG_TYPE ? (
                car?.monthly_income && (
                    <div>
                        <Text strong>Доход: </Text>
                        <Text>{car.monthly_income} ₽/мес.</Text>
                    </div>
                )
            ) : (
                <div>
                    <Text strong>Аренда с выкупом: </Text>
                    <Text>{car.daily_rent} ₽/дн.</Text>
                </div>
            )}
        </>
    );
};
