import React from 'react';
import type { FormProps } from 'antd';
import { Form, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { CarAPI } from '../api/cars-api';
import {
    IApplicationFormFieldType,
    IApplicationFormProps,
} from '../types/send-application-types';
import { OrangeButton } from './buttons/orange-button';
import { INVEST_CATALOG_TYPE } from '../variables';

export const ApplicationForm: React.FC<IApplicationFormProps> = ({
    carName,
    closeModal,
    catalogType,
}) => {
    // Форма для отправки заявки на аренду или покупку
    const [form] = Form.useForm();
    const { carId } = useParams();

    const onFinish: FormProps<IApplicationFormFieldType>['onFinish'] = async (
        values
    ) => {
        if (carId && values) {
            const data = { ...values, catalogType };
            await CarAPI.sendApplication(carId, data);
        }
        closeModal();
        form.resetFields();
    };
    return (
        <>
            <h1>
                {catalogType === INVEST_CATALOG_TYPE
                    ? 'Заявка на инвестирование'
                    : 'Заявка на аренду'}
            </h1>
            <Form
                form={form}
                name="basic"
                initialValues={{ carName: carName }}
                style={{ maxWidth: 500 }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <h3>Имя</h3>
                <Form.Item<IApplicationFormFieldType> name="name">
                    <Input size="large" />
                </Form.Item>
                <h3>Телефон</h3>
                <Form.Item<IApplicationFormFieldType>
                    name="phone"
                    rules={[
                        { required: true, message: 'Пожалуйста введите номер' },
                        {
                            pattern: /^(?:\+7|8)?\d{10}$/,
                            message:
                                'Пожалуйста, введите корректный номер телефона',
                        },
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
                <h3>Название автомобиля</h3>
                <Form.Item<IApplicationFormFieldType>
                    name="carName"
                    rules={[
                        {
                            required: true,
                            message: 'Введите название автомобиля',
                        },
                    ]}
                >
                    <Input size="large" />
                </Form.Item>

                <Form.Item>
                    <OrangeButton
                        children="Отправить"
                        handleClick={() => onFinish}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
