import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { UserAPI } from '../api/user-api';

export const ProtectedRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserStatus = async () => {
            const user = await UserAPI.checkCurrentUser();
            if (localStorage.getItem('token') && !user) {
                return navigate('/logout/', { replace: true });
            }
        };

        checkUserStatus();
    }, [navigate]);

    return <Outlet />;
};
