import axios, { AxiosError } from 'axios';

import {
    ICarLoaderParams,
    ICar,
    ICarsData,
    ICarsLoaderParams,
} from '../types/car-types';
import { IRent } from '../types/rent-types';
import { ISendApplicationData } from '../types/send-application-types';
import { RESPONSE_MESSAGE } from '../utils';
import { API_URL } from './utils';

export const CarAPI = {
    getCars: async ({ params }: ICarsLoaderParams) => {
        try {
            const response = await axios.get<ICarsData>(
                `${API_URL}cars?catalog_type=${params.catalog_type}&page=${params.page}`
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            RESPONSE_MESSAGE.error('Ошибка при получении автомобилей!');
            return axiosError;
        }
    },

    getCar: async ({ params }: ICarLoaderParams) => {
        try {
            const response = await axios.get<ICar>(
                `${API_URL}cars/${params.carId}/?catalog_type=${params.catalogType}`
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении автомобиля!');
            // TODO может быть стоит везде возвращать null но это не точно
            return null;
        }
    },

    getRents: async () => {
        try {
            const response = await axios.get<IRent[]>(
                API_URL + 'cars/my_rents/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении аренд!');
        }
    },

    getLicencePlates: async () => {
        try {
            const response = await axios.get<string[]>(
                API_URL + 'cars/licence_plates/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении номеров!');
        }
    },

    sendApplication: async (carId: string, data: ISendApplicationData) => {
        try {
            const response = await axios.post(
                `${API_URL}cars/${carId}/send_application/`,
                data
            );
            RESPONSE_MESSAGE.success('Заявка отправлена!');
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при отправлении заявки на аренду!');
        }
    },
};
