import { FC } from 'react';
import styles from './scheduled_service.module.less';
import { IScheduledService } from '../../types/scheduled-service-types';
import { OrangeButton } from '../buttons/orange-button';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../types/user-context-types';

interface IScheduledServiceListProps {
    scheduledServices: IScheduledService[];
    user: IUser;
}

export const ScheduledServiceList: FC<IScheduledServiceListProps> = ({
    user,
    scheduledServices,
}) => {
    const navigate = useNavigate();

    return (
        <div>
            {scheduledServices.length !== 0 && (
                <div className={styles.title__wrapper}>
                    <h1>Запланированные сервисы</h1>
                </div>
            )}
            <ul className={styles.cards}>
                {scheduledServices.map((service) => (
                    <li className={styles.cards__item} key={service.id}>
                        <article className={styles.card}>
                            <div className={styles.card__content}>
                                <h3 className={styles.card__title}>
                                    <b>{service.service}</b>
                                </h3>
                                <p className={styles.card__descr}>
                                    <span>
                                        <b>Выполнить до:</b>{' '}
                                        <span
                                            className={
                                                new Date() >
                                                new Date(service.scheduled_date)
                                                    ? styles.card__date__danger
                                                    : styles.card__date
                                            }
                                        >
                                            {service.scheduled_date.toLocaleString()}
                                        </span>
                                    </span>
                                </p>
                                <p className={styles.card__descr}>
                                    <b>Номер Машины: </b>
                                    {service.license_plate}
                                </p>
                                {service.comment && (
                                    <div className={styles.card__descr}>
                                        <b>Комментарий: </b>
                                        {service.comment}
                                    </div>
                                )}
                                {(user.role === 'ADMIN' ||
                                    user.role === 'MECHANIC') && (
                                    <div className={styles.card__btn}>
                                        <OrangeButton
                                            children={
                                                <>
                                                    <EditOutlined /> Изменить
                                                    сервис
                                                </>
                                            }
                                            handleClick={() =>
                                                navigate(
                                                    `/scheduled-service/${service.id}/edit/`
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </article>
                    </li>
                ))}
            </ul>
        </div>
    );
};
