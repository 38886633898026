import { ConfigProvider, Pagination } from 'antd';
import { FC } from 'react';

interface IPaginationProps {
    currentPage: number;
    handlePageChange: (page: number) => void;
    total: number;
}

export const MainPagePagination: FC<IPaginationProps> = ({
    currentPage,
    handlePageChange,
    total,
}) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Pagination: {
                        itemActiveBgDisabled: '#ff0000',
                        itemActiveColorDisabled: '#ff0000',
                        colorPrimary: '#6e3809',
                        colorPrimaryHover: '#e94d0f',
                        fontWeightStrong: 900,
                    },
                },
            }}
        >
            <Pagination
                current={currentPage}
                onChange={handlePageChange}
                total={total}
            />
        </ConfigProvider>
    );
};
