import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { UsersContext } from '../../context/user-context/user-context';
import { Preloader } from '../../components/preloader';

export const Logout = () => {
    const deleteCurrentUser = useContext(UsersContext)?.deleteCurrentUser;
    const navigate = useNavigate();

    useEffect(() => {
        deleteCurrentUser && deleteCurrentUser();
        navigate('/login/', { replace: true });
    }, [deleteCurrentUser, navigate]);

    return <Preloader />;
};
