import React from 'react';
import { ConfigProvider, Flex, Spin } from 'antd';

export const Preloader: React.FC = () => (
    <ConfigProvider
        theme={{
            components: {
                Spin: {
                    colorPrimary: '#ef9d43',
                    dotSizeLG: 40,
                },
            },
        }}
    >
        <Flex
            align="center"
            justify="center"
            style={{ height: 'calc(100vh - 148px)' }}
        >
            <Flex align="center" gap="middle">
                <Spin size="large" />
            </Flex>
        </Flex>
    </ConfigProvider>
);
