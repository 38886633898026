export const allowedFormats = ['pdf', 'png', 'jpg', 'jpeg', 'webp'];
export const uploadFilesMaxSize = 20;
export const notificationTitles = {
    WITHOUT_NOTIFICATIONS: 'Без уведомлений',
    MAIL: 'Почта',
    TELEGRAM: 'Телеграм',
};
export const DEFAULT_CATALOG_TYPE = 'rent_buy';
export const INVEST_CATALOG_TYPE = 'invest';
export const catalogTypes = [
    { id: DEFAULT_CATALOG_TYPE, title: 'Аренда и продажа' },
    { id: INVEST_CATALOG_TYPE, title: 'Инвестиции' },
];
