import { FC, MouseEvent, ReactNode } from 'react';
import styles from './orange-button.module.less';

export interface IOrangeButtonProps {
    children: ReactNode;
    handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const OrangeButton: FC<IOrangeButtonProps> = ({
    children,
    handleClick,
}) => {
    return (
        <button className={styles.edit__button} onClick={handleClick}>
            {children}
        </button>
    );
};
