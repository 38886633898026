// import { useRouteError } from 'react-router-dom';

export default function NotFoundPage() {
    // const error = useRouteError();
    // console.error(error);

    return (
        <div id="error-page">
            <h1 style={{ color: 'black' }}>Ничего не найдено!</h1>
        </div>
    );
}
