import { message } from 'antd';
import { IUser } from './types/user-context-types';

export const RESPONSE_MESSAGE = message;
RESPONSE_MESSAGE.config({
    top: 70,
});

export const getBase64 = (file: File): Promise<string> =>
    // Преобразует файл в строку для отправки на сервер
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const getStorageUser = () => {
    // Получаем данные пользователя из localStorage
    const storage_user: string | null = localStorage.getItem('user');
    let user: IUser | null = null;

    if (storage_user) {
        try {
            user = JSON.parse(storage_user);
        } catch (error) {
            RESPONSE_MESSAGE.error(
                'Ошибка получения пользователя из localStorage'
            );
        }
    }
    return user;
};

export const fileFormat = (link: string) => {
    // Принимает ссылку на файл и возвращает формат файла
    const format_arr = link.split('.');
    return format_arr[format_arr.length - 1];
};

export const openFileInNewTab = (url: string) => {
    // Принимает ссылку на файл и открывает документ в новомом окне
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const isInteger = (value: string) => {
    // Принимает строку и проверяем можно ли value
    // преобразовать в натуральное число
    return /^\d+$/.test(value);
};
