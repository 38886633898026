import { DatePicker, Form, FormProps, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CarAPI } from '../../api/cars-api';
import { Preloader } from '../preloader';
import { OrangeButton } from '../buttons/orange-button';
import styles from './scheduled_service_add_form.module.less';
import TextArea from 'antd/es/input/TextArea';
import { IAllServicesName } from '../../types/service-types';
import { IScheduledServiceFormFieldType } from '../../types/scheduled-service-types';
import dayjs from 'dayjs';
import { ServiceAPI } from '../../api/services-api';

export const AddSheduledServiceForm: FC = () => {
    const token = localStorage.getItem('token');

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [services, setServices] = useState<IAllServicesName[]>([]);
    const [licencePlates, setLicencePlates] = useState<string[]>([]);

    useEffect(() => {
        const fetchServicesData = async () => {
            const services = await ServiceAPI.getAllServicesNames();
            const licencePlates = await CarAPI.getLicencePlates();
            if (licencePlates) {
                setLicencePlates(licencePlates);
            }
            if (services) {
                setServices(services);
            }
        };
        fetchServicesData();
    }, [token]);

    const onFinish: FormProps<IScheduledServiceFormFieldType>['onFinish'] =
        async (values) => {
            const data = {
                license_plate: values.carLicensePlate,
                service: values.serviceName,
                scheduled_date: dayjs(values.scheduledDame).format(
                    'YYYY-MM-DD'
                ),
                comment: values.serviceComment ?? '',
            };
            await ServiceAPI.addScheduledService(data);
            form.resetFields();
            navigate('/user/');
        };

    if (
        !services ||
        !services.length ||
        !licencePlates ||
        !licencePlates.length
    ) {
        return <Preloader />;
    }

    return (
        <div className={styles.page__wrapper}>
            <h1>Запланировать сервис</h1>
            <Form
                form={form}
                onFinish={onFinish}
                className={styles.form}
                initialValues={{
                    carLicensePlate: licencePlates[0],
                    serviceName: services[0].name,
                }}
            >
                <h3>Номер автомобиля</h3>
                <Form.Item
                    className={styles.form__item}
                    name="carLicensePlate"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите автомобиль',
                        },
                    ]}
                >
                    <Select size="large">
                        {licencePlates &&
                            licencePlates.map((licencePlate, i) => (
                                <Select.Option value={licencePlate} key={i}>
                                    {licencePlate}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <h3>Название сервиса</h3>
                <Form.Item
                    className={styles.form__item}
                    name="serviceName"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите название сервиса.',
                        },
                    ]}
                >
                    <Select size="large">
                        {services &&
                            services.map((service) => (
                                <Select.Option
                                    value={service.name}
                                    key={service.id}
                                >
                                    {service.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <h3>Запланированная дата</h3>
                <Form.Item
                    className={styles.form__item}
                    name="scheduledDame"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите дату.',
                        },
                    ]}
                >
                    <DatePicker
                        size="large"
                        placeholder="Выберите дату"
                        className={styles.date__picker}
                    />
                </Form.Item>
                <h3>Комментарий</h3>
                <Form.Item className={styles.form__item} name="serviceComment">
                    <TextArea
                        size="large"
                        rows={4}
                        autoSize={{ minRows: 4, maxRows: 8 }}
                    />
                </Form.Item>
                <Form.Item className={styles.button__item}>
                    <OrangeButton
                        children="Отправить"
                        handleClick={() => onFinish}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
