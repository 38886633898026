import { FC } from 'react';
import { IMainPageCar } from '../../types/car-types';
import { NavLink } from 'react-router-dom';
import styles from './cars-list.module.less';
import { CustomImage } from '../custom-image/custom-image';
import { INVEST_CATALOG_TYPE } from '../../variables';

interface ICarListProps {
    cars: IMainPageCar[];
    catalogType: string;
}

export const CarsList: FC<ICarListProps> = ({ cars, catalogType }) => {
    return (
        <ul className={styles.car__cards}>
            {cars.map((car) => (
                <NavLink
                    to={`${car.id}/?catalog_type=${catalogType}`}
                    key={car.id}
                >
                    <li className={styles.cards__item}>
                        <article className={styles.card}>
                            <CustomImage src={car.photo} />
                            <div className={styles.card__content}>
                                <h3 className={styles.card__title}>
                                    {car.name}
                                </h3>
                                <p className={styles.card__descr}>
                                    {car.short_description}
                                </p>
                                <div className={styles.card__prices}>
                                    <p className={styles.card__price}>
                                        <b>Цена: </b>
                                        {car.price} ₽
                                    </p>
                                    {catalogType === INVEST_CATALOG_TYPE ? (
                                        car?.monthly_income && (
                                            <p className={styles.card__price}>
                                                <b>Доход: </b>
                                                {car.monthly_income} ₽/мес.
                                            </p>
                                        )
                                    ) : (
                                        <p className={styles.card__price}>
                                            <b>Аренда с выкупом: </b>
                                            {car.daily_rent} ₽/дн.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </article>
                    </li>
                </NavLink>
            ))}
        </ul>
    );
};
