import { FC, useEffect, useState } from 'react';
import { ApplicationModal } from '../../components/modal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Preloader } from '../../components/preloader';
import { Divider } from 'antd';
import styles from './car-page.module.less';

import { CarAPI } from '../../api/cars-api';
import { ICar } from '../../types/car-types';
import { CarCarusel } from '../../components/car-carusel/car-carusel';
import { CarDescription } from '../../components/car-description';
import { DEFAULT_CATALOG_TYPE } from '../../variables';
import { UserAPI } from '../../api/user-api';

export const CarPage: FC = () => {
    const [car, setCar] = useState<ICar | null>(null);
    const navigate = useNavigate();
    const { carId } = useParams();
    const [searchParams] = useSearchParams();
    const catalogType =
        searchParams.get('catalog_type') ?? DEFAULT_CATALOG_TYPE;

    useEffect(() => {
        if (carId) {
            const fetchServicesData = async (carId: string) => {
                const user = await UserAPI.checkCurrentUser();
                if (localStorage.getItem('token') && !user) {
                    return navigate('/logout/', { replace: true });
                }
                const car = await CarAPI.getCar({
                    params: { carId, catalogType },
                });
                setCar(car);
            };
            window.scrollTo(0, 0);
            fetchServicesData(carId);
        }
    }, [carId, catalogType, navigate]);

    if (!car) {
        return <Preloader />;
    }

    return (
        <div className={styles.content_wrapper}>
            {car && (
                <>
                    <div className={styles.carusel__wrapper}>
                        <CarCarusel car={car} />
                    </div>

                    <Divider />
                    <div className={styles.description__wrapper}>
                        <CarDescription car={car} catalogType={catalogType} />
                    </div>
                    <Divider />
                    <div className={styles.modal_button__wrapper}>
                        <ApplicationModal
                            catalogType={catalogType}
                            carName={car.name}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
