import React from 'react';
import type { FormProps } from 'antd';
import { Form, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserAPI } from '../../api/user-api';
import { IRegisterFieldType } from '../../types/user-register-types';
import styles from './register-page.module.less';
import { OrangeButton } from '../../components/buttons/orange-button';
import { notificationTitles } from '../../variables';
import { validateEmail } from '../../validators';

const onFinishFailed: FormProps<IRegisterFieldType>['onFinishFailed'] = (
    errorInfo
) => {
    // TODO Нужна обработка неправильной отправки формы
    // TODO нужно понять вообще в каких случаях она может упасть
    return errorInfo;
};

export const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish: FormProps<IRegisterFieldType>['onFinish'] = async (
        values
    ) => {
        const data = await UserAPI.register(
            values.fullname,
            values.phone,
            values.password,
            values?.email ?? '',
            values.notification
        );

        if (data) {
            navigate('/user/', { replace: true });
        }
    };

    return (
        <div className={styles.page__wrapper}>
            <h1>Регистрация</h1>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                className={styles.form}
                initialValues={{
                    notification: notificationTitles.WITHOUT_NOTIFICATIONS,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <h3>Полное имя</h3>
                <Form.Item<IRegisterFieldType>
                    name="fullname"
                    className={styles.form__item}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите имя!',
                        },
                    ]}
                >
                    <Input className={styles.input} />
                </Form.Item>
                <h3>Телефон</h3>
                <Form.Item<IRegisterFieldType>
                    className={styles.form__item}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите номер телефона!',
                        },
                    ]}
                >
                    <Input className={styles.input} />
                </Form.Item>
                <h3>Почта</h3>
                <Form.Item<IRegisterFieldType>
                    className={styles.form__item__email}
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'Не верный адрес!',
                        },
                        {
                            validator: (_, value) => validateEmail(form, value),
                        },
                    ]}
                >
                    <Input className={styles.input} />
                </Form.Item>
                <h3>Уведомления</h3>
                <Form.Item<IRegisterFieldType>
                    className={styles.form__item_select}
                    name="notification"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите пароль!',
                        },
                    ]}
                >
                    <Select size="large" className={styles.select}>
                        {Object.entries(notificationTitles).map(
                            ([key, value]) => (
                                <Select.Option key={key} value={key}>
                                    {value}
                                </Select.Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <h3>Пароль</h3>
                <Form.Item<IRegisterFieldType>
                    className={styles.form__item}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите пароль!',
                        },
                    ]}
                >
                    <Input.Password className={styles.input} />
                </Form.Item>
                <Form.Item
                    className={styles.button__item}
                    wrapperCol={{ offset: 8, span: 16 }}
                >
                    <OrangeButton
                        children="Зарегистрироваться"
                        handleClick={() => onFinish}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
