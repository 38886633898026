import {
    IAction,
    IS_AUTH,
    IUsersState,
    SET_TOKEN,
} from '../../types/user-context-types';

export const authReducer = (
    state: IUsersState,
    action: IAction
): IUsersState => {
    switch (action.type) {
        case IS_AUTH:
            return {
                ...state,
                isAuth: action.payload as boolean,
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload as string,
            };
        default:
            return state;
    }
};
