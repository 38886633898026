import { Carousel, Image } from 'antd';
import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import styles from './car-carusel.module.less';
import { ICar } from '../../types/car-types';
import { EyeOutlined } from '@ant-design/icons';

interface ICarCaruselProsp {
    car: ICar;
}

export const CarCarusel: FC<ICarCaruselProsp> = ({ car }) => {
    return (
        <Carousel
            className={styles.carusel}
            adaptiveHeight={true}
            arrows
            infinite={false}
            dots={{ className: styles.dots }}
        >
            {car.photos.map((photo, i) => (
                <div key={i} className={styles.carousel__image}>
                    <Image
                        src={photo.photo}
                        alt={car.name}
                        preview={{
                            mask: (
                                <div className={styles.custom__mask}>
                                    <EyeOutlined /> Увеличить изображения
                                </div>
                            ),
                        }}
                    />
                </div>
            ))}
            {car.videos?.map((video, i) => (
                <div key={i} className={styles.carusel__video}>
                    <ReactPlayer
                        controls
                        url={video.video}
                        width={'100%'}
                        height={'100%'}
                    />
                </div>
            ))}
        </Carousel>
    );
};
