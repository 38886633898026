import { FormInstance, UploadFile } from 'antd';
import { IRegisterFieldType } from './types/user-register-types';

export const uploadFormatValidate = async (
    value: UploadFile[],
    allowedFormats: string[]
) => {
    // Проверяет форматы входящих файлов и если их нет в списке разрешённых
    // возвращает ошибку
    if (!value || value.length === 0) {
        return Promise.resolve();
    }
    const invalidFiles = value.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return !allowedFormats.includes(fileExtension || '');
    });
    if (invalidFiles.length > 0) {
        const errorMessage = (
            <div
                style={{
                    paddingBottom: '5px',
                    textAlign: 'center',
                    maxWidth: '100%',
                }}
            >
                Следующие файлы имеют неподдерживаемый формат:{' '}
                {invalidFiles.map((file) => file.name).join(', ')}. Разрешены
                только {allowedFormats.join(', ')}.
            </div>
        );
        return Promise.reject(errorMessage);
    }
    return Promise.resolve();
};

export const fileMaxSizeValidate = async (
    value: UploadFile[],
    maxSize: number
) => {
    // Проверяет максимальный размер загружаемых файлов и
    // если он превышает максимальное значение возвращает ошибку
    if (!value || value.length === 0) {
        return Promise.resolve();
    }
    const allFilesSize: number[] = value
        .map((el) => {
            if (el.size) {
                return Math.round(el.size / 1024) / 1024;
            }
            return 0; // Возвращаем 0, если размер не определен
        })
        .filter((size): size is number => size !== undefined);
    const totalSize: number = allFilesSize.reduce((acc, el) => acc + el);
    if (totalSize && totalSize > maxSize) {
        const errorMessage = (
            <div
                style={{
                    paddingBottom: '5px',
                    textAlign: 'center',
                    maxWidth: '100%',
                }}
            >
                Размер загружаемых файлов не может привышать {maxSize}MB.
            </div>
        );
        return Promise.reject(errorMessage);
    }
    return Promise.resolve();
};

export const validateEmail = (
    form: FormInstance<IRegisterFieldType>,
    value: string
) => {
    // Проверяем если вполе выбора уведомлений выбрата почта значит адрес почты,
    // должен быть заполнен
    const notification = form.getFieldValue('notification');
    if (notification === 'MAIL' && !value) {
        const errorMessage = (
            <div
                style={{
                    paddingBottom: '5px',
                    maxWidth: '100%',
                }}
            >
                Пожалуйста, введите адрес электронной почты
            </div>
        );
        return Promise.reject(errorMessage);
    }
    return Promise.resolve();
};
