import { useContext, useEffect, useState } from 'react';
import { Rents } from '../../components/rents/rents';
import { ServicesList } from '../../components/services/services';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './user-page.module.less';
import { PlusCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { UsersContext } from '../../context/user-context/user-context';
import { Preloader } from '../../components/preloader';
import { getStorageUser } from '../../utils';
import { OrangeButton } from '../../components/buttons/orange-button';
import { IRent } from '../../types/rent-types';
import { IService } from '../../types/service-types';
import { CarAPI } from '../../api/cars-api';
import { ScheduledServiceList } from '../../components/scheduled_service/scheduled_service';
import { IScheduledService } from '../../types/scheduled-service-types';
import { ServiceAPI } from '../../api/services-api';

export const UserPage = () => {
    const [rents, setRents] = useState<IRent[]>(() => []);
    const [services, setServices] = useState<IService[]>([]);
    const [scheduledServices, setScheduledServices] = useState<
        IScheduledService[]
    >([]);
    const setUser = useContext(UsersContext)?.setUser;
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const user = getStorageUser();

    const fetchScheduledServicesData = async () => {
        const scheduledServices = await ServiceAPI.getScheduledServices();
        setScheduledServices(scheduledServices ?? []);
    };
    const fetchServicesData = async () => {
        const services = await ServiceAPI.getServices();
        setServices(services ?? []);
    };
    const fetchRentsData = async () => {
        const rents = await CarAPI.getRents();
        setRents(rents ?? []);
    };

    useEffect(() => {
        if (!user && setUser) {
            setUser();
        }
    }, [setUser, user]);

    useEffect(() => {
        if (!rents.length && user?.role === 'CLIENT') {
            fetchRentsData();
        }
    }, [rents.length, user?.role]);

    useEffect(() => {
        if (!scheduledServices.length && token) {
            fetchScheduledServicesData();
        }
        if (!services.length && token) {
            fetchServicesData();
        }
    }, [services.length, scheduledServices.length, token]);

    if (!user || !token) {
        return <Preloader />;
    }

    return (
        <div className={styles.page__wrapper}>
            <div className={styles.title__wrapper}>
                <h1>Cтраница пользователя </h1>
                <span>{user.fullname}</span>
            </div>
            {!rents.length && user.role === 'CLIENT' && (
                <h3>У вас нет действующих аренд</h3>
            )}
            <NavLink to="/services/examples/" className={styles.examples__link}>
                Как выполнять сервисы! <QuestionCircleOutlined />
            </NavLink>
            <div
                className={
                    user.role === 'ADMIN' || user.role === 'MECHANIC'
                        ? styles.buttons__wrapper
                        : styles.button__wrapper
                }
            >
                {user.role === 'ADMIN' && (
                    <OrangeButton
                        children="Зарегистрировать пользователя"
                        handleClick={() => navigate('/register/')}
                    />
                )}
                {(user.role === 'ADMIN' || user.role === 'MECHANIC') && (
                    <>
                        <OrangeButton
                            children={
                                <>
                                    <PlusCircleFilled /> Добавить сервис
                                </>
                            }
                            handleClick={() => navigate('/services/add/')}
                        />

                        <OrangeButton
                            children={<>Запланировать сервис</>}
                            handleClick={() =>
                                navigate('/scheduled-service/add/')
                            }
                        />
                    </>
                )}
                {user.role === 'CLIENT' && rents.length !== 0 && (
                    <OrangeButton
                        children={
                            <>
                                <PlusCircleFilled /> Добавить сервис
                            </>
                        }
                        handleClick={() => navigate('/services/add/')}
                    />
                )}
            </div>
            {user.role === 'CLIENT' && <Rents rents={rents} />}
            <ScheduledServiceList
                user={user}
                scheduledServices={scheduledServices}
            />
            <ServicesList services={services} />
        </div>
    );
};
