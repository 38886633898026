import React, { useState } from 'react';
import { Modal } from 'antd';
import { OrangeButton } from './buttons/orange-button';
import { ApplicationForm } from './application-form';
import { IApplicationModalProps } from '../types/send-application-types';
import { INVEST_CATALOG_TYPE } from '../variables';

export const ApplicationModal: React.FC<IApplicationModalProps> = ({
    carName,
    catalogType,
}) => {
    const [open, setOpen] = useState(false);

    const showModal: () => void = () => {
        setOpen(true);
    };

    const handleCancel: () => void = () => {
        setOpen(false);
    };

    return (
        <>
            <OrangeButton
                children={
                    catalogType === INVEST_CATALOG_TYPE
                        ? 'Подать заявку  на инвестирование'
                        : 'Подать заявку на аренду'
                }
                handleClick={showModal}
            />
            <Modal open={open} onCancel={handleCancel} footer={null}>
                <ApplicationForm
                    closeModal={() => setOpen(false)}
                    carName={carName}
                    catalogType={catalogType}
                />
            </Modal>
        </>
    );
};
