import React from 'react';
import { Image } from 'antd';
import styles from './service-files-list.module.less';
import { IServiceFiles } from '../../types/service-types';
import pdfFileImage from '../../component-media/pdf_file1.webp';
import { fileFormat, openFileInNewTab } from '../../utils';

export const ServiceFilesList = ({
    fileData,
}: {
    fileData: IServiceFiles[];
}) => {
    return (
        <div className={styles.list__wrapper}>
            {fileData.map((imgData: IServiceFiles) => (
                <div className={styles.image__wrapper} key={imgData.id}>
                    {fileFormat(imgData.file) === 'pdf' ? (
                        <div
                            onClick={() => openFileInNewTab(imgData.file)}
                            className={styles.link}
                        >
                            {' '}
                            <Image
                                alt="pdf"
                                src={pdfFileImage}
                                className={styles.pdf__image}
                                preview={false}
                            />
                            <div className={styles.link__mask}>
                                <div>Открыть</div>
                            </div>
                        </div>
                    ) : (
                        <Image
                            alt=""
                            src={imgData.file}
                            className={styles.image}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};
