import {
    IAction,
    IUsersState,
    SET_USER,
    DELETE_CURRENT_USER,
    IUser,
} from '../../types/user-context-types';

export const usersReducer = (
    state: IUsersState,
    action: IAction
): IUsersState => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload as IUser,
            };
        case DELETE_CURRENT_USER:
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
};
