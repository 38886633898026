import { useEffect, useReducer } from 'react';

import { usersReducer } from './user-reducer';
import { UserAPI } from '../../api/user-api';
import { UsersContext } from './user-context';
import {
    IUsersProviderProps,
    IUsersState,
    LOADING,
    SET_USER,
    DELETE_CURRENT_USER,
    IS_AUTH,
    SET_TOKEN,
} from '../../types/user-context-types';
import { authReducer } from './auth-reducer';
import axios from 'axios';

export const UsersState: React.FC<IUsersProviderProps> = ({ children }) => {
    const initialState: IUsersState = {
        user: null,
        loading: false,
        isAuth: false,
        token: '',
    };
    const [usersState, usersDispatch] = useReducer(usersReducer, initialState);
    const [authState, authDispatch] = useReducer(authReducer, initialState);

    const setToken = (token: string) => {
        authDispatch({ type: SET_TOKEN, payload: token });
    };

    const setIsAuth = (isAuth: boolean) => {
        authDispatch({ type: IS_AUTH, payload: isAuth });
    };
    const setLoading = (loading: boolean) => {
        usersDispatch({ type: LOADING, payload: loading });
    };
    const setUser = async (): Promise<void> => {
        setLoading(true);
        const user = await UserAPI.getCurrentUser();
        if (user) {
            usersDispatch({ type: SET_USER, payload: user });
            localStorage.setItem('user', JSON.stringify(user));
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
        setIsAuth(true);
        setLoading(false);
    };
    const deleteCurrentUser = async (): Promise<void> => {
        usersDispatch({ type: DELETE_CURRENT_USER });
        setToken('');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsAuth(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }, [usersState, authState]);

    return (
        <UsersContext.Provider
            value={{
                user: usersState.user,
                loading: usersState.loading,
                isAuth: authState.isAuth,
                token: authState.token,
                setUser,
                deleteCurrentUser,
                setToken,
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};
